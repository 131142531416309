<template>
  <div class="bg--log bg--log-fitness">
    <div class="header-login">
      <a href="#" class="header-login__logo"></a>
    </div>
    <div class="container">
      <div class="login-blocks">
        <div class="login-block login-block--fitness">
          <h1 class="login__tit login__tit--fitness">
            LerChek <span>Fintess</span>
          </h1>
          <h1 class="login__tit login__tit--recipes">
            LerChek <span>Recipes</span>
          </h1>
          <h1 class="login__tit login__tit--makeup">
            LerChek <span>Мakeup</span>
          </h1>
          <h1 class="login__tit login__tit--tummy">
            Плоский <span>Животик</span>
          </h1>
          <p class="login__txt login__txt--fitness">
            Теория и практика
          </p>
          <form class="login-form">
            <div class="login-form__blocks">
              <div class="login-form__block">
                <span class="login-form__block-icon"></span>
                <input type="email"
                  class="login-form__input"
                  placeholder="Укажите ваш email"
                  v-model="user.email"
                />
              </div>
              <div class="login-form__block">
                <span class="login-form__block-icon login-form__block-icon--2"></span>
                <input :type="showPassword ? 'text' : 'password'"
                  class="login-form__input" placeholder="Пароль"
                  v-model="user.password"
                />
                <button class="login-form__hide" @click.prevent="showPassword = !showPassword" />
              </div>
            </div>
            <div class="button__block">
              <button class="login-form__button" @click.prevent="login" :disabled="loading">
                {{ loading ? 'Загрузка...' : 'Войти' }}
              </button>
              <span class="error" v-if="error">{{ error }}</span>
            </div>
          </form>
        </div>
        <div class="recipes__bg"></div>
      </div>
    </div>
    <div class="recipes__bg-img recipes__bg-img--2"></div>
    <div class="recipes__bg-img"></div>
    <picture>
      <source srcset="@/assets/images/tummy/tummy_mob.png 1x,@/assets/images/tummy/tummy_mob@2x.png 2x" media="(max-width: 768px)">
      <img src="@/assets/images/tummy/tummy.png" srcset="@/assets/images/tummy/tummy@2x.png 2x" alt="img" class="tummy__img">
    </picture>
    <img src="@/assets/images/tummy/tummy_2.png" srcset="@/assets/images/tummy/tummy_2@2x.png 2x" alt="img" class="tummy__img-2">
  </div>
</template>
<script>
export default {
  data() {
    return {
      showPassword: false,
      user: {
        email: null,
        password: null,
      },
      error: null,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email;
      this.user.password = this.$route.query.password;
      this.login();
    }
  },
  methods: {
    async login() {
      this.loading = true;
      this.error = '';
      this.user.guide = this.guideId;
      const response = await this.$request.post('/api/guide/login', this.user);
      if (response.status !== 200 || !response.data.success) {
        this.error = 'Неправильный логин или пароль';
      } else {
        localStorage.setItem('token', response.data.result.token);
        this.$router.replace('/');
      }
      this.loading = false;
    },
  },
  computed: {
    guideId() {
      if (process.env.VUE_APP_VIDEO_GUIDE_ID) {
        return process.env.VUE_APP_VIDEO_GUIDE_ID;
      }
      return 1;
    },
  },
};
</script>
<style scoped>
.error {
  color: #f00;
  display: block;
  font-size: 1.6rem;
  margin-top: 2rem;
}
</style>
